import React from "react";
import {graphql} from "gatsby";
import "../../assets/map/singleRegionMap.scss";
import PageWrapper from "../../components/PageWrapper";
import JobApplication from "../../sections/careers/JobApplication";
import {Helmet} from "react-helmet/es/Helmet";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text, Box } from "../../components/Core";
import {getProperty} from "../../utils/helperFn";

const StrapiJobs = (props) => {

    const job = props.data.strapiJobs;

    const jobSections = [
        'About us',
        'Job purpose',
        'Key responsibilities',
        'Key requirements',
        'Benefits'
    ];

    const renderJobSection = (section) => {
        const sectionData = getProperty(job, section.replace(' ', '_').toLowerCase());

        return sectionData && <>
            <div className="pt-5"/>
            <Row className="justify-content-left text-left">
                <Col lg="12">
                    <Text color="heading" as="h3" fontSize="1.5em" fontWeight={500} letterSpacing={-0.75}mb={0}>
                        {section}
                    </Text>
                    <Text dangerouslySetInnerHTML={{ __html: sectionData.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></Text>
                </Col>
            </Row>
        </>
    }

    return (
        <>
            <Helmet>
                <title>Netomnia | {job.title}</title>
                <meta name="description" content={`Netomnia | ${job.title}`}/>
            </Helmet>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"/>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="hero">
                                    {getProperty(job, 'title')}
                                </Title>
                            </Col>
                        </Row>
                        <div className="pt-5"/>
                        <Row className="justify-content-left text-left">
                            <Col lg="12">
                                <Text><strong>Location:</strong> {getProperty(job, 'location')}</Text>
                                <Text><strong>Start:</strong> {getProperty(job, 'start')}</Text>
                                <Text><strong>Job type:</strong> {getProperty(job, 'full_time') ? 'Full time' : 'Part time'}</Text>
                            </Col>
                        </Row>
                        {
                            jobSections.map(element => {
                                return renderJobSection(element)
                            })
                        }
                    </Container>
                </Section>
            </PageWrapper>
            <JobApplication 
                title={getProperty(job, 'title')}
                location={'/jobs/' + getProperty(job, 'slug')}
            />
        </>
    );

}

export const data = graphql`
    query getStrapijobsOnSlug($slug: String) {
        strapiJobs(slug: {eq: $slug}) {
            title
            full_time
            location
            slug
            start
            job_purpose
            about_us
            key_requirements
            key_responsibilities
            benefits
        }
   }`

export default StrapiJobs