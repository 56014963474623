import React, { useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Input, Button } from "../../components/Core";

const JobApplication = ({ title, location }) => {   

    const [formState, setFormState] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        subject: title,
        message: "",
        attachmentType: "",
        attachmentName: "",
        attachment: ""
    })
    
    const onChange = (e) => {
        setFormState({...formState, [e.target.name]: e.target.value });
    }

    const handleAttachment = (e) => {
        const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > 10) {
            alert('File size exceeds 10 MiB');
            if (document !== undefined) {
                document.getElementById('file-attachment').value = "";
            }
            e.target.value = '';
            return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        const attachmentName = e.target.files[0].name;
        const attachmentType = e.target.files[0].type;

        

        reader.onload = function () {
          setFormState({ 
            ...formState,
            ['attachment']: reader.result,
            ['attachmentName']: attachmentName,
            ['attachmentType']: attachmentType
          })
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
    }

    const handleSubmit = (e) => {
        if (document !== undefined) {
            document.getElementById('submit-button').classList.add('is-loading');
            document.getElementById('submit-button').innerHTML = "Sending...";
        }
        e.preventDefault()
        fetch('/.netlify/functions/sendmail', {
            method: 'POST',
            body: JSON.stringify(formState),
        })
            .then(
                () => {
                    alert('Email sent successfully!')
                    if (document !== undefined) {
                        document.getElementById("application-form").reset();
                        document.getElementById('submit-button').classList.remove('is-loading');
                        document.getElementById('submit-button').innerHTML = "Send Message";
                    }
                }
            )
            .catch(
                (error) => {}
            )
    }
  
  return (
    <>
      <PageWrapper footerDark>
            <Section className="pt-5 mt-5">
                <Container>
                    <Row className="justify-content-center text-center">
                        <Col lg="8">
                            <div className="banner-content">
                                <Title>Apply for {title}</Title>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col sm={12} md={8} className="order-lg-1 pt-5 mt-4">
                            <form data-netlify="true" name="applicationForm" method="post" onSubmit={handleSubmit} id="application-form">
                                <input type="hidden" name="form-name" value="applicationForm" />
                                    <Row>
                                        <Col md={6} className="mb-32">
                                            <Input
                                                type="text"
                                                name="first_name"
                                                placeholder="First name"
                                                onChange={onChange}
                                                required
                                            />
                                        </Col>
                                        <Col md={6} className="mb-32">
                                            <Input
                                                type="text"
                                                name="last_name"
                                                placeholder="Last name"
                                                onChange={onChange}
                                                required
                                            />
                                        </Col>
                                        <Col md={6} className="mb-32">
                                            <Input
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                onChange={onChange}
                                                required
                                            />
                                        </Col>
                                        <Col md={6} className="mb-32">
                                            <Input
                                                type="number"
                                                name="phone"
                                                placeholder="Phone Number"
                                                onChange={onChange}
                                                required
                                            />
                                        </Col>
                                        <Col md={12} className="mb-32">
                                            <Input
                                                type="text"
                                                as="textarea"
                                                rows={4}
                                                name="message"
                                                placeholder="Message"
                                                onChange={onChange}
                                                required
                                            />
                                        </Col>
                                        <Col md={12} className="mb-32">
                                            <label>Be sure to include an updated resume (DOC, DOCX, PDF)</label>
                                            <Input
                                                type="file"
                                                placeholder="Attachment"
                                                id="file-attachment"
                                                onChange={handleAttachment}
                                                accept=".doc, .docx, .pdf"
                                                required
                                            />
                                        </Col>
                                        <Col md={12} className="mb-32 text-center">
                                            <Button width="60%" id="submit-button" type="submit" borderRadius={10}>
                                                Send Message
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </PageWrapper>
    </>
  );
};
export default JobApplication;
